/* ==========================================================================
 Resets
 ========================================================================== */

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

nav, a, .button {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

button:focus { outline:none; }

::-moz-selection { background: #b6d4fc; text-shadow: none; }

::selection { background: #b6d4fc; text-shadow: none; }

audio, canvas, img, video { vertical-align: middle; }

fieldset { border: 0; margin: 0; padding: 0; }

textarea { resize: vertical; }

/* ==========================================================================
 End Resets
 ========================================================================== */

html { background: #273033; font-size: 1em; line-height: 1.4; }

body { margin:0; }

*, *:before, *:after {
-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

.recipe hr { width:120%; border-color: #5e5c5b; height: 2px; margin: 30px 0 30px -26%; }

/* typography and links */
p { font: 1em Helvetica, Arial, sans-serif; color:#1976d2; line-height:1.4; }
ul { padding-inline-start:30px; margin:0.5em 0; }
li { font: 1.1em Helvetica, Arial, sans-serif; color:#d9d9d9; line-height:1.5;}
h1 { font:600 3em bebas-neue-pro, sans-serif; font-weight: 400; color:#26c4b4; margin:0.5em 0 0; }
h2 { font:400 1.8em bebas-neue-pro, sans-serif; font-weight: 400; color:#26c4b4; margin:0.5em 0 0; }
h3 { font: 400 1em Helvetica, Arial, sans-serif; color:#26c4b4; margin:1.5em 0 0; }
h4 { font:400 2em bebas-neue-pro, sans-serif; color:#4e9ce9; margin:0; }
h5 { font: 1.25em Helvetica, Arial, sans-serif; color:#1976d2; font-weight:bold; font-style:italic; line-height:inherit; margin:0.5em; }

button { font:400 1.5em bebas-neue-pro, sans-serif; font-weight: 400; border:none; cursor: pointer; text-transform: capitalize; background:none;  }
a { text-decoration:none; color:#1976d2; transition:0.4s; -moz-transition:0.4s; -webkit-transition:0.4s; margin:0; }
a:hover { text-decoration:none; color:#ccc; }
a.button { font-weight:bold; }
::placeholder { font-size: .8em; text-align:left; opacity:0.9; }
:-ms-input-placeholder { font-size: .8em; text-align:left;}
::-ms-input-placeholder { font-size: .8em; text-align:left;}

.black { z-index:-1; background-color:black; background-attachment: fixed; position:fixed; height:100%; width:100%; top:0; left:0; opacity:0.2; display:block;}
#wrapper { width: 100%; border-top:6px solid #1976d2; margin:0 auto; text-align:center; padding:40px 10px; }
header { display:grid; width: 670px; margin:0 auto; }
.nav { display:grid; grid-template-columns: auto auto auto auto; grid-template-rows: 55px; grid-gap:2px; width:100%; margin-top:35px; color:#e5e5e5; text-transform:capitalize; }
.nav button { text-decoration:none; color:#e5e5e5; background:#1976d2; transition:0.4s; -moz-transition:0.4s; -webkit-transition:0.4s; text-transform: capitalize; height:100%; }
.nav button:hover { background:#004ba0; cursor:pointer; }
.nav button.nav-right { background:#ff8f00; }
.nav button.nav-right:hover { background:#c56000; }
img.logo { width:200px; height:auto;}

#container { position:relative; width:670px; text-align:left; height:auto; padding:35px 15px 35px 150px; margin:20px auto 0 auto; background:rgba(35, 35, 35, 0.6); box-shadow: inset 0px 0px 15px #18978a; border-radius:6px; }
#x { top:0; padding:5px; font-size:1.4em; z-index:5; }
#x button { color:#999999; }
.x-abs { position:absolute; right:-5px; }
.x-fixed { position:fixed; right: 10px; }
.recipe { position: relative; right: 5px; }
#show { width:120%; margin: 12px 0 5px -25%;  text-align:center; }
#show p { font-style: italic; color:#c0bebe; }

/*keyword search section*/
#search { display:grid; grid-template-columns:30% 70%; grid-template-rows:55px; width:670px; margin:20px auto 0 auto; }
#search form { justify-self: end; }
#search input { height:100%; font-size:1em; background-color:#e5e5e5; outline:none; border:0; text-align:left; border-top-left-radius: 6px; border-bottom-left-radius: 6px; padding:0 10px; vertical-align: top; }

.searchBtn { height:100%; padding:15px; background:#009688; color:#ffffff; border:none; transition:0.6s; -moz-transition:0.6s; -webkit-transition:0.6s; border-top-right-radius: 6px; border-bottom-right-radius: 6px; }
.listAllBtn { background:#ff8f00; color:#ffffff; border:none; transition:0.6s; -moz-transition:0.6s; -webkit-transition:0.6s; border-radius:6px; padding:15px 0; }

.searchBtn:hover { background:#00675b; cursor:pointer;  }
.listAllBtn:hover { background: #c56000; }

footer { border-top: 1px solid #888; position: relative; text-align:left; font-size:0.9em; max-width:665px; background:#273033; padding:5px; z-index: 5; margin: 35px auto 0 auto;  }

/*classes*/
.nav-mid { margin-right: 2px; }
.nav-left { margin-right: 2px; border-top-left-radius: 6px; border-bottom-left-radius: 6px; }
.nav-right { border-top-right-radius: 6px; border-bottom-right-radius: 6px; }

/* smoothie images: */
.smoothie { position:absolute; top:50px; left:55px; z-index:2; }
.smoothie-multi { position:absolute; top:10px; left:-105px; z-index:5;}
.smoothie img, .smoothie-multi img { width:55px; height:auto; }
/* keyword div */
.keyword-wrapper { position:absolute; width:55px; height:auto; margin:15px 0 0 -100px; z-index:2; padding-bottom:10px; }
.listAll-wrapper { position:absolute; width:55px; height:auto; margin:15px 0 0 -100px; z-index:2; padding-bottom:10px; }

.visuallyhidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

/* ==========================================================================
 Media Queries
 ========================================================================== */

@media only screen and (max-width: 823px) {
  /* Style adjustments for viewports that meet the condition */
  /* font sizes: */
  p { font: 1.1em Helvetica, Arial, sans-serif; color:#3689dc; line-height:inherit; }
  ul { padding-inline-start:22px; }
  h1 { font-size: 2.5em; margin:0.5em 0 0; }
  h4 { font-size: 1.6em; }
  h3 { margin:0.5em 0 1em; }
  /*++++++++++++++++++++++*/
  #wrapper { height:100%; border-radius:0; margin:0 auto; border-top:6px solid #1976d2; padding:8px; }
  header, #search { width:100%; }
  .nav { grid-template-rows: 40px; }
  #search { grid-template-columns:35% 65%; grid-template-rows: 40px; }
  #search button { padding:0 10px; }
  img.logo { display:none; }
  .nav { margin:20px auto 0 auto; }
  #container { width: 100%; font-size:0.9em; padding:10px 15px 15px 20px; }
  .smoothie img { display:none; }
  .recipe ul { padding-inline-start: 25px; }
  /* *********/
  /* smoothie images: */
  /* HR: */
  .recipe hr { width:96%; margin: 10px 0;  }
  #show { width:100%; margin: 25px 0; }
  footer { max-width:99%; }
}

@media only screen and (max-width: 650px) {
  button { font-size: 1.3em; }
}

@media only screen and (max-width: 520px) { 
  /* iphone x, pixel 2, etc... */
  /* .nav button { width:99%; } */
  footer { padding:5px; }
  #search { grid-template-columns:40% 60%; }
  #search input { max-width: 150px; }
}

@media only screen and (max-width: 400px) { 
  /* iphone x, pixel 2, etc... */
  #search { grid-template-columns:45% 55%; }
  #search input { max-width: 120px; }
  .listAllBtn { padding:0;}
}

/* ==========================================================================
 Print styles.
 Inlined to avoid required HTTP connection: h5bp.com/
 ========================================================================== */

@media print {
  * {
      background: transparent !important;
      color: #000 !important; /* Black prints faster: h5bp.com/s */
      box-shadow: none !important;
      text-shadow: none !important;
  }

  a,
  a:visited {
      text-decoration: underline;
  }

  a[href]:after {
      content: " (" attr(href) ")";
  }

  abbr[title]:after {
      content: " (" attr(title) ")";
  }

  /*
   * Don't show links for images, or javascript/internal links
   */

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
      content: "";
  }

  pre,
  blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
  }

  thead {
      display: table-header-group; /* h5bp.com/t */
  }

  tr,
  img {
      page-break-inside: avoid;
  }

  img {
      max-width: 100% !important;
  }

  @page {
      margin: 0.5cm;
  }

  p,
  h2,
  h6 {
      orphans: 6;
      widows: 6;
  }

  h2,
  h6 {
      page-break-after: avoid;
  }
}